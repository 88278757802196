import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.nicescroll.min'

// 自定义滚动条插件
if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        $('body').niceScroll({
            mousescrollstep: 40,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#BDBDBD',
            cursorborder: '1px solid #BDBDBD',
            cursorwidth: '5px',
            cursoropacitymax: 0.6,
            smoothscroll: true,
            iframeautoresizeL: true,
        });
    }
}
function resizeNiceScroll() {
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
}

//首页banner图
var screenWidth = window.screen.width;
var indexinterleaveOffset = 0.4; //视差比值

var bannerswiper = new Swiper(".index-banner", {
    direction: "vertical",
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable: true,
    },
    on: {
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * indexinterleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(0," + innerTranslate + "px, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
});

//首页关于我们swiper
var aboutswiper1 = new Swiper(".index-about .right .cont1 .mySwiper", {
    // autoHeight: true, //高度随内容变化
    loop: true,
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    }
});
var aboutswiper2 = new Swiper(".index-about .right .cont2 .mySwiper", {
    // autoHeight: true, //高度随内容变化
    loop: true,
    autoplay: {
        delay: 4000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    }
});

//页面滚动监听
$(document).scroll(function () { 
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();        
    var contentH = $(document).height();

    //pc端首页产品部分效果
    let proTop = $('.index-product-pc').offset().top
    let marketingTop = $('.index-marketing').offset().top

    //产品部分文字浮动
    if (scroH <= proTop){
        $('.index-product-pc').removeClass('fixed')
    }else if (scroH > proTop && scroH < marketingTop - viewH){
        $('.index-product-pc').addClass('fixed').removeClass('abs')
    } else if (scroH >= marketingTop - viewH){
        $('.index-product-pc').addClass('abs')
    }

    //产品部分背景色改变、左侧导航对应
    let $colorbgEl = $('.index-product-pc .color-bg')
    let $navElArr = $('.index-product-pc .nav-cont .nav-list ul li')
    let $textElArr = $('.index-product-pc .text-cont .item')
    let imgTop1 = $('.index-product-pc .img-cont .item').eq(0).offset().top
    let imgTop2 = $('.index-product-pc .img-cont .item').eq(1).offset().top
    let imgTop3 = $('.index-product-pc .img-cont .item').eq(2).offset().top
    let imgTop4 = $('.index-product-pc .img-cont .item').eq(3).offset().top
    // let imgTop5 = $('.index-product-pc .img-cont .item').eq(4).offset().top
    // let imgTop6 = $('.index-product-pc .img-cont .item').eq(5).offset().top
    if (scroH < imgTop1 + viewH/2){
        $colorbgEl.css('background','#1b3993');
        $navElArr.eq(0).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop1 + viewH / 2 && scroH < imgTop2 + viewH / 2){
        $colorbgEl.css('background', '#e7380d');
        $navElArr.eq(1).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop2 + viewH / 2 && scroH < imgTop3 + viewH / 2) {
        $colorbgEl.css('background', '#f9be00');
        $navElArr.eq(2).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop3 + viewH / 2 && scroH < imgTop4 + viewH / 2) {
        $colorbgEl.css('background', '#fb7c2f');
        $navElArr.eq(3).addClass('active').siblings().removeClass('active')
    } 
    // else if (scroH >= imgTop4 + viewH / 2 && scroH < imgTop5 + viewH / 2) {
    //     $colorbgEl.css('background', '#fb7c2f');
    //     $navElArr.eq(4).addClass('active').siblings().removeClass('active')
    // } else if (scroH >= imgTop5 + viewH / 2 ) {
    //     $colorbgEl.css('background', '#17abf1');
    //     $navElArr.eq(5).addClass('active').siblings().removeClass('active')
    // }
    //产品部分右侧文字对应
    if (scroH < imgTop1 + viewH / 4) {
        $textElArr.eq(0).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop2 - viewH / 4 && scroH < imgTop2 + viewH / 4){
        $textElArr.eq(1).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop3 - viewH / 4 && scroH < imgTop3 + viewH / 4) {
        $textElArr.eq(2).addClass('active').siblings().removeClass('active')
    } else if (scroH >= imgTop4 - viewH / 4 && scroH < imgTop4 + viewH / 4) {
        $textElArr.eq(3).addClass('active').siblings().removeClass('active')
    }
    //  else if (scroH >= imgTop5 - viewH / 4 && scroH < imgTop5 + viewH / 4) {
    //     $textElArr.eq(4).addClass('active').siblings().removeClass('active')
    // } else if (scroH >= imgTop6 - viewH / 4) {
    //     $textElArr.eq(5).addClass('active').siblings().removeClass('active')
    // }
    else{
        $textElArr.removeClass('active')
    }
    
    //marketing文字横向滚动
    if (scroH > marketingTop - viewH){
        let tanx = scroH - marketingTop + viewH
        $('.index-marketing .top-scroll-text').css('transform', 'translateX(-' + tanx/5 +'px)')
    }
});


// pc端首页点击产品左侧导航
$('.index-product-pc .nav-list li').on('click',function(){
  let index = $(this).index()
  console.log(index)
    $('html ,body').animate({ scrollTop: $('.index-product-pc .img-cont .item').eq(index).offset().top + 'px' }, 300);
    return false;
})